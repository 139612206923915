<template>
  <div>
    <div>
      <h1 class="text-center">
        {{ title }}
      </h1>
      <div class="d-flex justify-content-between">
        <b-form-input
          id="address"
          v-model="filter.name_uz"
          placeholder="Поиск"
          class="w-25 mb-1"
          @input="searchFilter"
        />

        <b-button
          variant="success"
          class="btn-tour-skip text-right mb-2"
          @click="tableToExcel"
        >
          <feather-icon
            icon="DownloadIcon"
            size="16"
          /> Юклаб олиш
        </b-button>
      </div>

      <CategoryFilter
        ref="categoryFilter"
        :category_id="filter.category_id"
        @onSuccess="setFiter"
      />
      <b-card>
        <el-row
          class="mb-2"
          :gutter="20"
        >
          <el-col :span="6">
            <v-select
              id="category_id"
              v-model="filter.parent0_category_id"
              :options="categoriesLevel0"
              :reduce="(option) => option.id"
              class="w-100"
              style="width:100%"
              placeholder="Категория"
              label="name_uz"
              @input="parent0change($event)"
            />
          </el-col>
          <el-col
            v-if="filter.parent0_category_id"
            :span="6"
          >
            <v-select
              id="category_id"
              v-model="filter.parent1_category_id"
              :options="categoriesLevel1"
              :reduce="(option) => option.id"
              class="w-100"
              label="name_uz"
              placeholder="Подкатегория"
              @input="parent1change($event)"
            />
          </el-col>
          <el-col
            v-if="filter.parent1_category_id"
            :span="6"
          >
            <v-select
              id="category_id"
              v-model="filter.parent2_category_id"
              :options="categoriesLevel2"
              :reduce="(option) => option.id"
              placeholder="Номенклатура"
              class="w-100"
              label="name_uz"
              @input="parent2change($event)"
            />
          </el-col>
        </el-row>

        <save
          ref="saveForm"
          @onSuccess="getItems"
        />
        <b-overlay
          variant="white"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
          :show="loading"
        >
          <el-table
            v-if="!loading"
            ref="treeTable"
            :data="warehouseProducts"
            row-key="id"
            border
            :row-class-name="tableRowClassName"
          >
            <el-table-column
              type="index"
              :index="indexMethod"
            />
            <el-table-column
              label="Наименование"
              width="600"
            >
              <template slot-scope="scope">
                <span
                  class="c-pointer fs16"
                >
                  <span
                    :class="scope.row.models_with_parent && scope.row.models_with_parent.cost_dollar?'text-primary':'text-muted'"
                    @click="openModal(scope.row.category_id)"
                  >
                    <i class="el-icon-star-off" />
                    {{ scope.row.models_with_parent?scope.row.models_with_parent.name_uz:'' }}
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="Количество"
            >
              <template
                slot-scope="scope"
              >
                <span
                  v-if="scope.row && scope.row.quantity"
                  class="fs18 font-weight-bolder"
                >{{ scope.row.quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Цена продажа"
            >
              <template
                v-if="scope.row && scope.row.models_with_parent && scope.row.models_with_parent.price_dollar"
                slot-scope="scope"
              >
                <span v-if="scope.row.models_with_parent.price_dollar">
                  {{ (Number(scope.row.models_with_parent.price_dollar) * dollarRate)|formatPrice }} so'm
                </span>
                <br>
                <span v-if="scope.row.models_with_parent.price_dollar">
                  {{ scope.row.models_with_parent.price_dollar|formatPrice }} $
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="['director', 'admin', 'deputy'].includes(user.role.name)"
              label="Цена прихода"
            >
              <template
                slot-scope="scope"
              >
                <template v-if="scope.row.models_with_parent.cost || scope.row.models_with_parent.cost_dollar">
                  <span v-if="scope.row.models_with_parent.cost">
                    {{ scope.row.cost |formatPrice }} so'm
                  </span>
                  <br>
                  <span v-if="scope.row.models_with_parent.cost_dollar">
                    {{ scope.row.models_with_parent.cost_dollar |formatPrice }}$
                  </span>
                </template>
                <template v-else>
                  <span class="text-warning">
                    Товар не прибыл
                  </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-if="isAdmin"
              label="Цена рассрочка"
            >
              <template
                slot-scope="scope"
              >
                <span v-if="scope.row.models_with_parent.price_dollar_installment">
                  {{ scope.row.models_with_parent.price_dollar_installment |formatPrice }}$
                </span>
              </template>
            </el-table-column>
            <el-table-column

              width="250"
            >
              <template
                slot-scope="scope"
              >
                <b-button
                  v-if="scope.row && scope.row.models_with_parent.cost_dollar && !['marketer', 'hired_worker'].includes(user.role.name)"
                  v-can="'settings.product_categories-update-price'"
                  size="sm"
                  variant="outline-primary mr-1"
                  @click="addCategory(scope.row)"
                >
                  Изменить цену
                </b-button>

                <b-button
                  v-can="'warehouses.product_categories-update'"
                  size="sm"
                  variant="outline-info mt-1"
                  @click="$router.push({name:'ProductInfo', params:{id:scope.row.models_with_parent.id}})"
                >
                  Редактировать
                </b-button>
              </template>
            </el-table-column>
          </el-table>
          <div
            v-else
            style="height:550px"
          />
          <!-- excel -->
          <el-table
            v-if="!loading"
            v-show="false"
            class="excelTable"
            :data="warehouseProductsForExcel"
            row-key="id"
            border
            :row-class-name="tableRowClassName"
          >
            <el-table-column
              type="index"
              :index="indexMethod"
            />
            <el-table-column
              label="Наименование"
              width="600"
            >
              <template slot-scope="scope">
                <span
                  class="c-pointer fs16"
                >
                  <span
                    :class="scope.row.models_with_parent && scope.row.models_with_parent.cost_dollar?'text-primary':'text-muted'"
                    @click="openModal(scope.row.category_id)"
                  >
                    <i class="el-icon-star-off" />
                    {{ scope.row.models_with_parent?scope.row.models_with_parent.name_uz:'' }}
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="Количество"
            >
              <template
                slot-scope="scope"
              >
                <span
                  v-if="scope.row && scope.row.quantity"
                  class="fs18 font-weight-bolder"
                >{{ scope.row.quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Цена продажа"
            >
              <template
                v-if="scope.row && scope.row.models_with_parent && scope.row.models_with_parent.price_dollar"
                slot-scope="scope"
              >
                <span v-if="scope.row.models_with_parent.price_dollar">
                  {{ (Number(scope.row.models_with_parent.price_dollar) * dollarRate)|formatPrice }} so'm
                </span>
                <br>
                <span v-if="scope.row.models_with_parent.price_dollar">
                  {{ scope.row.models_with_parent.price_dollar|formatPrice }} $
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="['director', 'admin', 'deputy'].includes(user.role.name)"
              label="Цена прихода"
            >
              <template
                slot-scope="scope"
              >
                <template v-if="scope.row.models_with_parent.cost || scope.row.models_with_parent.cost_dollar">
                  <span v-if="scope.row.models_with_parent.cost">
                    {{ scope.row.cost |formatPrice }} so'm
                  </span>
                  <br>
                  <span v-if="scope.row.models_with_parent.cost_dollar">
                    {{ scope.row.models_with_parent.cost_dollar |formatPrice }}$
                  </span>
                </template>
                <template v-else>
                  <span class="text-warning">
                    Товар не прибыл
                  </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-if="isAdmin"
              label="Цена рассрочка"
            >
              <template
                slot-scope="scope"
              >
                <span v-if="scope.row.models_with_parent.price_dollar_installment">
                  {{ scope.row.models_with_parent.price_dollar_installment |formatPrice }}$
                </span>
              </template>
            </el-table-column>
          </el-table>
        </b-overlay>
        <!--  -->
        <el-pagination
          background
          :total="filter.total"
          :page-size="1 * filter.per_page"
          layout="prev, pager, next"
          class="mt-3"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
        <!--  -->
        <b-modal
          id="modal-lg"
          v-model="showPartyModal"
          size="xl"
          cancel-variant="outline-secondary"
          hide-footer
          no-close-on-backdrop
          centered
          @close="showPartyModal = false"
        >
          <div class="hello">
            <WarehouseProductInfo
              :p-category-id="pCategoryId"
              :is-component="true"
            />
          </div>
        </b-modal>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { setItem } from '@/utils/storage'
import CategoryFilter from '@/components/categoryFilter.vue'
import vSelect from 'vue-select'
import { debounce } from 'debounce'
import Save from '../save.vue'

import 'v-dock-menu/dist/v-dock-menu.min.css'

export default {
  name: 'Index',
  components: {
    Save,
    vSelect,
    CategoryFilter,
    WarehouseProductInfo: () => import('@/views/warehouse/products/warehouseProductInfo.vue'),
  },
  props: {
  },
  data() {
    return {
      showPartyModal: false,
      showProductModal: false,
      page: 1,
      loading: false,
      items: [],
      warehouseProductsForExcel: [],
      warehouseProducts: [],
      categoriesLevel0: [],
      categoriesLevel1: [],
      categoriesLevel2: [],
      pagination: {
        page: 1,
        per_page: 50,
        total: 0,
      },
      filter: {
        per_page: 50,
        total: 0,
        page: 1,
        name_uz: null,
        category_id: null,
        parent0_category_id: null,
        parent1_category_id: null,
        parent2_category_id: null,
        category_name: '',
        warehouse_id: null,
      },
      selected: null,
      dollarRate: 0,
      action: false,
      pCategoryId: null,
      defaultExpandAll: false,
      uri: 'data:application/vnd.ms-excel;base64,',
      template: '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) { return window.btoa(unescape(encodeURIComponent(s))) },
      format(s, c) { return s.replace(/{(\w+)}/g, (m, p) => c[p]) },
    }
  },
  computed: {
    ...mapGetters({
      product_categories: 'productCategory/GET_ITEMS',
      treeNodes: 'productCategory/GET_TREE',
      warehouses: 'warehouse/GET_ITEMS',
    }),
    user() {
      return this.$store.getters['auth/USER']
    },
    warehouseId() {
      return this.$route.params.id
    },
    title() {
      if (this.warehouses && this.warehouses.data && this.warehouses.data.length && this.warehouses.data.find(el => el.id == this.warehouseId)) {
        return this.warehouses.data.find(el => el.id == this.warehouseId).name_uz
      }
      return ''
    },
  },
  watch: {
  },
  mounted() {
    this.getCategoriesByLevel(0)

    if (this.$route.query.parent0_category_id) {
      this.filter.parent0_category_id = Number(this.$route.query.parent0_category_id)
    }
    if (this.$route.query.parent1_category_id) {
      this.getCategoriesByLevel(2, Number(this.$route.query.parent1_category_id))
      this.filter.parent1_category_id = Number(this.$route.query.parent1_category_id)
    }
    if (this.$route.query.parent2_category_id) {
      this.filter.parent2_category_id = Number(this.$route.query.parent2_category_id)
      this.getCategoriesByLevel(3, Number(this.$route.query.parent2_category_id))
    }
    this.getDollarRates({ current_dollar_rate: 1 }).then(res => {
      if (res.data) {
        this.dollarRate = res.data.data[0]?.amount
      }
    })
  },
  methods: {
    ...mapActions({
      getWarehouses: 'warehouse/index',
      getItemsAction: 'productCategory/tree',
      getCategories: 'productCategory/index',
      getDollarRates: 'resource/getDollarRates',
      getWarehouseProducts: 'productCategory/warehouseProductsByWarehouse',
    }),
    searchFilter: debounce(function (search) {
      this.getItems()
    }, 500),

    async getItems() {
      this.selected = null
      this.loading = true
      if (this.warehouseId) {
        this.filter.warehouse_id = this.warehouseId
      }
      await this.getWarehouseProducts(this.filter).then(({ data }) => {
        this.warehouseProducts = data.data
        this.filter.total = data.total
        this.filter.page = data.current_page
      })

      this.loading = false
    },
    indexMethod(index) {
      return ((Number(this.filter.page) - 1) * Number(this.filter.per_page) + index + 1)
    },
    handleCurrentChange(val) {
      this.filter.page = val
      this.getItems()
    },
    handleSizeChange(val) {
      this.filter.per_page = val
      this.getItems()
    },
    setFiter(arr) {
      if (arr.length) {
        const parent = arr[0]
        this.filter.category_name = arr[0].name_uz
        if (parent) {
          // this.categoriesLevel0 = null
          // this.categoriesLevel1 = null
          // this.categoriesLevel2 = null
          // this.filter.parent0_category_id = null
          // this.filter.parent1_category_id = null
          // this.filter.parent2_category_id = null
          this.filter[`parent${parent.level}_category_id`] = parent.id
          this.getItems()
        }
      }
    },
    clearFilter() {
      this.filter.category_name = ''
      this.filter.category_id = null
      this.getItems()
    },
    findChildChildArrayPath(array, id) {
      let path = null
      array.some((childArray, i) => {
        const found = childArray.children.some((element, j) => {
          if (Array.isArray(element.children)) {
            const foundInChild = element.children.some((childElement, k) => {
              if (childElement.id === id) {
                path = [i, j, k]
                return true
              }
            })
            if (foundInChild) {
              return true
            }
          }
        })
        if (found) {
          return true
        }
      })
      return path
    },
    findChildChildChildArrayPath(array, id) {
      let path = null
      array.some((childArray, i) => {
        const found = childArray.children.some((element, j) => {
          if (Array.isArray(element.children)) {
            const foundInChild = element.children.some((childElement, k) => {
              if (Array.isArray(childElement.children)) {
                const foundInChildInChild = childElement.children.some((childChildElement, l) => {
                  if (childChildElement.id === id) {
                    path = [i, j, k, l]
                    return true
                  }
                })
                if (foundInChildInChild) {
                  return true
                }
              }
            })
            if (foundInChild) {
              return true
            }
          }
        })
        if (found) {
          return true
        }
      })
      return path
    },
    openModal(id) {
      this.showPartyModal = true
      this.pCategoryId = id
    },
    expandTable() {
      this.loading = true
      if (this.defaultExpandAll) {
        setItem('productTableExpandAll', false)
        this.defaultExpandAll = false
      } else {
        this.defaultExpandAll = true
        setItem('productTableExpandAll', true)
      }
      setTimeout(() => {
        this.loading = false
      }, 0)
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.getWhProduct(row)) {
        if (this.getWhProduct(row).models_with_parent.is_cost_price === 1) {
          return 'warning-row'
        } if (this.getWhProduct(row).models_with_parent.is_cost_price === 0) {
          return 'success-row'
        }
      }
      return ''
    },
    select(item, isSelect) {
      this.selected = isSelect ? item : null
    },
    selectedRow(item) {
      this.selected = item
    },
    addCategory(selected) {
      if (selected) {
        this.$refs.saveForm.add(selected)
      } else {
        this.$refs.saveForm.visible = true
      }
    },
    getWhProduct(row) {
      return this.warehouseProducts.find(el => el.category_id === row.category_id)
    },
    async parent0change(event) {
      // this.categoriesLevel2 = null
      this.filter.parent1_category_id = null
      this.filter.parent2_category_id = null
      this.changeQuery('parent0_category_id', event)
      await this.getCategoriesByLevel(1, event)
    },
    parent1change(event) {
      this.filter.parent2_category_id = null
      this.getCategoriesByLevel(2, event)
      this.changeQuery('parent1_category_id', event)
    },
    parent2change(event) {
      this.changeQuery('parent2_category_id', event)
      this.getItems()
    },
    getCategoriesByLevel(level, parentId = null) {
      this.loading = true
      this.getCategories({ level, parent_id: parentId, per_page: 9999 })
        .then(res => {
          this[`categoriesLevel${level}`] = res.data.data
        })
        .finally(() => {
          this.getItems()
          this.loading = false
        })
    },
    async tableToExcel(table) {
      const filterModel = { ...this.filter }
      filterModel.per_page = 9999
      await this.getWarehouseProducts(filterModel).then(({ data }) => {
        this.warehouseProductsForExcel = data.data
      })

      const tableeee = document.querySelector('div.excelTable div.el-table__body-wrapper table')
      console.log(tableeee)
      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = { worksheet: `${this.title}`, table: table.innerHTML }
        const link = document.createElement('a')
        link.download = `${this.title}.xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
      }, 10)
    },
  },
}
</script>

<style>
  .node-title {
    font-size: 18px;
  }
  .el-table .warning-row {
    background: #fffcf6;
  }

  .el-table .success-row {
    background: #e9fff2;
  }
  .menu-bar-container{
    position: absolute !important;
  }
</style>
