<template>
  <div>
    <ProductsTable />
  </div>
</template>

<script>
import ProductsTable from './components/tableWarehouseProducts.vue'

export default {
  name: 'Index',
  components: {
    ProductsTable,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>
  .node-title {
    font-size: 18px;
  }
  .el-table .warning-row {
    background: #ffeecf;
  }

  .el-table .success-row {
    background: #e9fff2;
  }
</style>
